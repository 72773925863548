import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2a61563a3bca8d7074d2c1d8010f22d1/3f8aa/returnal.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC8klEQVQozx2TS08bZxSGvYkEwdgztsfj8YXBBuP7eMZxfQGM41CHcHGAwmCaGhmHEEPAaZKiJO2iNGoroqhCiZKqpYsuKqGqitpFpUj9d0+/dnWks3j0nfd9Poe/2kCKG3hTJu5gDGUyicsXRE1mkCMx9EIZj5hKPMWoN4DkD+GUFGR9Cnk8yYh/jGFJY0TVcYbjOGIb24zfWsVvVQlaFdI3F9GmcriUMLKsstP9jPqNOgnTQPKqqCEdyeNHCUWRQjE8AuwK6LgFfMjlx2EcPiDX2yfT6VLq7jLd62GsrGEst6ivrvPhr0ueDh7S7tjUbjaZX7OJJjKEonHcagTfZAp3YAyXpjPsVnCku3vE17ZI2tuUBLT15DHW2iaNe3fZ6Pf5+/0fnPQfsH2nzeb9A9oPH9PaOyB7vYl3MoM/bf4Pu+pSGBqRcYzNL4ilhZYv4Z8y2DgeUFxcwVxpcaNl8/r8DU+Ojllf/4TO3iELd7oYiy0se4vYTIPorSXUYgVP0kQaT+AYFtSRUQ+yGsYpK7hEPk5JZTKTYL89T1mUcnuzI85dotpcQjMquLUoE8UqUbPE1GxDPKiAmi+jGCUcThG+RwQdy+Tx+AL4VI0rToWjwSYf/vmZw7N37PzwI7md+yKvNIHpOSRtDJ8/KErS8IrWXWKGsxZeUYxDKdfxFqaxbtsEsx+hmVW8ySKDL/r89PslJxe/sfzoGdqyzVWfhpK9hjus41EC6BNJRiUfV4ZG0fPXkAMRAazMEytVmPl0ldTsHOb8DLmZAjvHbToHbYzaLPa+TXVljol0FN2yUKI5IqKMdLlMJJXHJfuQo/95KTxMiBNOn5d4fZbglzdZ3v+aZnfX4u25wden0wyOs7w8TXJxnuHlt1lefZ/h1VmFF6cl/rwUu+9Mmk3hok8ILzRypBcWsHvX6fYrPH9q8c2LIrXFBu17y+w/u8vFuyJffVlgq1fHtjN8vJTj0UmB3U6C1nqOo89rHPbTlGum+DEh/gWdwHLVK9LcewAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Returnal Title",
            "title": "Returnal Title",
            "src": "/static/2a61563a3bca8d7074d2c1d8010f22d1/e3189/returnal.png",
            "srcSet": ["/static/2a61563a3bca8d7074d2c1d8010f22d1/a2ead/returnal.png 259w", "/static/2a61563a3bca8d7074d2c1d8010f22d1/6b9fd/returnal.png 518w", "/static/2a61563a3bca8d7074d2c1d8010f22d1/e3189/returnal.png 1035w", "/static/2a61563a3bca8d7074d2c1d8010f22d1/3f8aa/returnal.png 1337w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Gameplay`}</h3>
    <p>{`Let's get down to brass tacks: Returnal is hard. Some of that difficulty is deserved and to be expected. Many of the enemies spawn bullet hell patterns that you need to learn how to dodge, or learn to interrupt with a single bullet. See, not taking damage is the name of this game and it's embodied in two game systems: Adrenaline and Integrity. The adrenaline system rewards you greatly for not taking a hit. Every few monsters you kill, you are rewarded with a new perk that helps you navigate the planet. You can get improved vision, which places a little red orb around them so you don't need to rely on the minimap to tell you their locations, a stronger melee attack, improved proficiency gain so you gain stronger weapons faster, etc. If you take a hit, your adrenaline starts all over. This is a great way to reward you for your good play.`}</p>
    <p>{`What's not great is to take that same philosophy and apply it to your health pool. Most rooms will contain at least one health pack that when picked up, recovers some amount of health...or if you are at full health gives you one bar of progress towards increasing your overall health. This is the opposite of the adrenaline system. You can make do without proficiency gain or a stronger melee, you cannot make do without health. I'd much rather there be two different pickups: one to recover health and the other to help permanently increase your bar. There is currently an exploit where you can return to your ship to sleep and fully recover and then return to the rooms to pick up the health drops but I fully expect this to be patched out or at least tweaked so you can't do it indefinitely.`}</p>
    <p>{`The bosses I've encountered so far, Phrike and Ixion, have all been at the perfect difficulty level. They've been tough with their own unique patterns to memorize, but still feel fair because they are the only enemy on screen. The game is honestly at it's toughest in the thick of the biomes themselves, where sometimes the game will spawn ten or more enemies all at once and expect you to deal with it.`}</p>
    <p>{`What feels unfair is the games darkness, at least in the first biome. On several occasions I've fallen off a ledge to my doom just because I couldn't see that ledge. When the game first booted up, I turned up the gamma most of the way as I do for most games these days, but it just doesn't seem to be enough and I can't seem to adjust it anymore. Sure I can "scan the environment" to get a nice clear picture and I do often, but I can't be expected to do that in the middle of a firefight where multiple flying face huggers have spawned and are dive bombing me.`}</p>
    <h3>{`Environment`}</h3>
    <p>{`All three biomes I've encountered so far look great (well, what I can see of the first biome) and feel different for the most part. Housemarque nailed the general atmosphere of this game perfectly. There's an overwhelming sense of oppression and dread around every corner. Bodies of aliens, and your previous iterations, are piled up all over the place, buildings and statues are crumbling, and your house is always there. Each visit to it after clearing a biome gives you a little more insight into Selene's past and each time you feel like something is about to jump out at you, but it never seems to. This game gets by very well on the sense of impending horror that never seems to arrive. The enemies I've encountered have ranged from well designed and animated be-tentacled horrors to robots lacking any of the inspiration of the enemies from the first and second biomes. `}</p>
    <h3>{`Progression`}</h3>
    <p>{`Here's where I think the game needs a bit of tweaking. Returnal leans way more towards the roguelike and not towards rougelite, which means very little is actually carried over between runs. There are a few unlocks that are permanent, like your sword, and the ether currency also stays with you but that's about it. You mostly carry over your experience of dealing with each enemy between runs. I'd be fine with this if there were more weapons to unlock or they unlocked faster. I've encountered five so far through the first three biomes and it doesn't feel like a lot, so on those runs where things go badly, quickly, you don't feel like you've accomplished anything. The same goes for the artifacts you can unlock. These can be unlocked with ether, or lucked upon in the wild. This is fine, except I don't see many artifacts just randomly dropping, so if you want to find them you need to participate in the lottery that is the malignant chests scattered throughout the world. These never seem worth it because you don't know what malfunction you'll get and if you'll be able to fix it quickly.`}</p>
    <p>{`I'm normally down with this kind of progression. I have 180 hours in Risk of Rain 2 on Steam, with a bunch more on the Switch, and it also has an ether-like currency in it's lunar tokens. The difference there is you are constantly unlocking items or new characters with their own play styles or new abilities for said characters, and those items are abundant in the world. It would be nice if Returnal bumped up the drop rate of consumables and artifacts just a bit.`}</p>
    <h3>{`Final Thoughts`}</h3>
    <p>{`Returnal is a game bordering on greatness. It's difficulty and drip feed of new content can make it seem ponderous at times, but there is a lot to like here if you like atmospheric alien suspense and roguelikes. I don't think this game will have the same pull as Risk of Rain or Gunfire Reborn, but I definitely want beat it at least once...if I can.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      